import React, { Component, PureComponent } from 'react';

import AnimBackground2 from "./AnimBackground2";
import animations from "../animations";
import { Link } from "react-router-dom";

import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { NumeroMenu } from "../../Numero/components/views/NumeroMenu";

import "../style/menu.scss";
import classNames from "classnames";
import Socials from "../../Numero/components/Socials";
import { isEmptyArticle } from "../../Index";


let Flickity;

function waitAnimationEnd(fn, duration)  {
    setTimeout(() => {
        fn();
    }, duration);
}

class ChapitreMenu extends Component {

    constructor(props){
        super(props);

        this.ref = "";


        if(this.props.articles.length > 0)
            this.state = {
                backgroundImage: this.props.articles[0].wallpaper.formats.menu,
                backgroundImageHover: this.props.articles[0].wallpaper.formats.menu
            };
        else
            this.state = {
                backgroundImage: "",
                backgroundImageHover: ""
            };
    }

    checkWindowWidth() {
        return window.innerWidth <= 425
    };

    shouldComponentUpdate(nextProps) {
        return (this.props.show !== nextProps.show && nextProps.show)
    }

    changeBackgroundHover(article) {
        this.setState({
            backgroundImageHover: article.wallpaper.formats.menu
        });
    };

    changeBackgroundOut = () => {
        this.setState({
            backgroundImageHover: this.state.backgroundImage
        });
    };

    setReference = (ref) => {
        this.ref = ref;
    };

    componentDidMount() {

        import('react-flickity-component').then((module) => {
            Flickity = module.default;
            this.setState({
                scrollbarLoaded: true
            });
        });

    }

    componentDidUpdate() {
        if(this.checkWindowWidth()) {
            let width = this.ref.getBoundingClientRect().width;
            animations.fixWidth(this.ref, width)
        }
    }

    render() { let that = this; const { articles, category, fonts, numero, toggleMenu } = this.props;

        const flickityOptions = {
            initialIndex: 0,
            wrapAround: false,
            cellAlign: 'left',
            freeScroll: true,
            // groupCells: false,
            draggable: false,
            contain: true,
            pageDots: false
        };

        if(this.props.articles)

        return(
            <li key={ category._id } className="chapitre">
                <div className="inner">
                    <div className="categories" style={ fonts.family1 }>
                        { !isEmptyArticle(articles[0]) &&
                            <Link to={`/${numero.slug}/${category.slug}/${articles[0].slug}`}
                                  onClick={ toggleMenu } key={ articles[0]._id }>
                                <span className="inner">
                                    <span className="chapitre-title">
                                        <span className="inner">{ category.title}</span>
                                    </span>
                                </span>
                            </Link>
                        }
                        { isEmptyArticle(articles[0]) &&
                            <span className="inner">
                                <span className="chapitre-title">
                                    <span className="inner">{ category.title}</span>
                                </span>
                            </span>
                        }
                    </div>
                    {this.state.scrollbarLoaded && <div className="articles-outer" ref={(ref) => this.setReference(ref) }>
                        <Flickity  className={'articles'} // default ''
                                                              elementType={'ul'} // default 'div'
                                                              options={flickityOptions} // takes flickity options {}
                                                              disableImagesLoaded={false} // default false
                                                              reloadOnUpdate // default false
                                                              static // default false
                    >
                            { articles.slice(1).map(article => {

                            return(
                                <li className="article-li" key={ article._id } style={ fonts.family3 }
                                    onMouseOver={ () => this.changeBackgroundHover(article) }
                                    onMouseLeave={ this.changeBackgroundOut }>
                                    <Link to={`/${numero.slug}/${category.slug}/${article.slug}`}
                                          onClick={ toggleMenu } key={ article._id }
                                    >
                                        <div className="wallpaper" style={
                                            { backgroundImage : 'url(' + article.wallpaper.formats.menu + ')' } } >
                                            <div className="title-outer" style={ fonts.family1 }>
                                                <span className="title">
                                                    { article.title }
                                                </span>
                                            </div>
                                        </div>
                                        <span className="who">
                                            <span className="name">{article.author.first_name} {article.author.last_name}</span>
                                            <span className="metier"> {article.author.metier }</span>
                                        </span>
                                    </Link>

                                </li>
                            )



                        })}
                        </Flickity>
                    </div>
                    }
                </div>
            </li>
        )

    }


}

class MenuView extends Component {

    constructor(props){
        super(props);

        this.ref = "";
        this.footerRef = "";

        this.state = {
            render: false,
            animBackground: false,
            socialMenu: null
        };

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.show !== prevState.show)
            return {
                show: nextProps.show
            };

        return null;
    }

    animationMenu(show) {

        if(show){

            this.setState({ render: !this.state.render });

            setTimeout(function() {
                document.getElementsByClassName("menu")[0].scrollTo(0, 0);
                document.getElementsByClassName("menu-inner")[0].scrollTo(0, 0);
                document.getElementsByClassName("articles-test")[0].scrollTo(0, 0);
            },50);

            setTimeout(() => {
                animations.onEnter(this.ref);
                animations.footerMenuEnter(this.footerRef);
            }, 10);

        } else {

            this.setState({
                animBackground: !this.state.animBackground,
            });

            animations.onLeave(this.ref);
            animations.footerMenuLeave(this.footerRef);

            setTimeout(() => {
                this.setState({
                    render: !this.state.render,
                });
            }, 10);

        }
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    setFooterReference = (ref) => {
        this.footerRef = ref;
    };

    componentDidUpdate(prevProps, prevState) {

        if(prevState.animBackgroundAnimation !== true)
            if(prevProps.show !== this.state.show){
                this.animationMenu(this.state.show);
            }

    }

    componentDidMount(){ let socialMenu;

        this.setState({
            show: this.props.show
        });
    }

    getCategories(categories) {

        const categoriesArr = categories.slice(0);
        categoriesArr.sort((a, b) => {
            return (a.order - b.order)
        });

        return categoriesArr.slice(0);
    }

    getArticles(articles, category) {
        return articles.filter((article) => article.category._id === category._id);

    }

    openLink = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);

        this.props.history.push(link);
    };

    checkWindowWidth() {
        return window.innerWidth <= 425
    };

    renderSocials(socials, logo) {

        if(this.checkWindowWidth()) {
            if (this.props.data.numeros) {
                return <Socials socials={ socials } logo={ logo } menu={ true }/>
            }
        }
        return null;
    }

    render(){ let that = this;

        if(typeof window === "undefined") return null;

        let firstCat = this.props.numero.categories[0];
        let lastCat = this.props.numero.categories[this.props.numero.categories.length - 1];
        let firstArticle = that.getArticles(this.props.numero.articles, firstCat)[0];
        let lastArticle = that.getArticles(this.props.numero.articles, lastCat)[0];

        const classes = classNames({
            'toggled': this.state.render,
            'menu'   : true
        });

        return (
            <SettingsContext.Consumer>
                {({ fonts, contact, logo, socials }) => (
                    <div className={ classes } style={ this.state.style }>
                        <AnimBackground2 animation={ this.state.animBackground } show={ this.state.render } />

                        <div className="menu-inner" ref={ this.setReference }>
                            { /*this.state.numeroMenu */}
                            <div className="articles-test">
                                <div className="title" style={ fonts.family1 }>
                                    <span className="sommaire">Sommaire</span>
                                    <span className="sommaire-title">{ this.props.numero.custom.titre }</span>
                                </div>
                                <ul>
                                    <li className="edito chapitre-article chapitre">
                                        <Link to={`/${this.props.numero.slug}/${firstArticle.category.slug}/${firstArticle.slug}`}
                                              onClick={ this.props.toggleMenu } key={ firstArticle._id }
                                        >
                                            <div className="wallpaper" style={
                                                { backgroundImage : 'url(' + firstArticle.wallpaper.formats.menu + ')' } } >
                                            </div>
                                            <div className="titles" style={ fonts.family1 }>
                                                    <span className="category">
                                                        {firstArticle.custom.subtitle}
                                                    </span>
                                                <span className="article-title">
                                                        { firstArticle.title }
                                                    </span>
                                            </div>
                                        </Link>
                                    </li>
                                    {that.getCategories(this.props.numero.categories).slice(1, -1).map((category, index) => {

                                        const articles = that.getArticles(this.props.numero.articles, category);

                                        return(<ChapitreMenu
                                            category={ category }
                                            articles={ articles }
                                            fonts={ fonts }
                                            toggleMenu={ this.props.toggleMenu }
                                            numero={ this.props.numero }
                                            show={ this.state.render }
                                            key={ index }
                                        />);

                                    })}
                                    <li className="chiffre-cle chapitre-article chapitre">
                                        <Link to={`/${this.props.numero.slug}/${lastArticle.category.slug}/${lastArticle.slug}`}
                                              onClick={ this.props.toggleMenu } key={ lastArticle._id }
                                        >
                                            <div className="wallpaper" style={
                                                { backgroundImage : 'url(' + lastArticle.wallpaper.formats.menu + ')' } } >
                                            </div>
                                            <div className="titles" style={ fonts.family1 }>
                                                    <span className="category">
                                                        { lastArticle.custom.subtitle }
                                                    </span>
                                                <span className="article-title">
                                                        { lastArticle.title }
                                                    </span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="footer-menu" ref={ this.setFooterReference }>

                            { this.renderSocials(socials, logo) }

                            <div className="inner-footer-menu">
                                <ul style={ fonts.family3 }>
                                    <li>
                                        <Link to={"/credits"}
                                              onClick={(e) => this.openLink("/credits", e) }>
                                            Crédits
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/mentions-legales"}
                                              onClick={(e) => this.openLink("/mentions-legales", e) }>
                                            Mentions légales
                                        </Link>
                                    </li>
                                    {/*contact &&
                                    <li>
                                        <a href={"mailto:" + contact}
                                           onClick={ this.props.captureContactClick }>
                                            Contact
                                        </a>
                                    </li>
                                    */}
                                    <li>
                                        <div onClick={ this.props.openRGPDPopup }>
                                            Gestion des cookies
                                        </div>
                                    </li>
                                    <li>
                                        <a href="https://rapportannuel2018.habitat-en-region.fr/" target="_blank">
                                        Rapport annuel 2018
                                        </a>
                                    </li>
                                </ul>
                                <div className="credits" style={ fonts.family3 }>
                                    Tous droits réservés © <a href="https://www.habitat-en-region.fr/" target="_blank"><img className="logo-her" src={ logo.horizontal } alt=""/></a></div>
                            </div>
                        </div>
                    </div>
                )}
            </SettingsContext.Consumer>
        )
    }
}

export default MenuView;
