import classNames from "classnames";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as React from "react";
import { ButtonGo } from "./ButtonGo";
import Timeline from "./Timeline";
import {isEmptyArticle} from "../Index";

class Player extends PureComponent {

    constructor(props)  {
        super(props);

        this.state = {
            fullPlayer: false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this.events();
        window.addEventListener('scroll', this.handleScroll);
    }

    setHeightCouverture() {

        // let heightCouverture = require('ios-inner-height');
        // if(heightCouverture() === window.innerHeight) {
        //     if(!this.state.fullPlayer)
        //         this.setState({
        //             fullPlayer: true
        //         });
        //
        // } else {
        //     if(this.state.fullPlayer)
        //         this.setState({
        //             fullPlayer: false
        //         });
        // }

    }

    handleScroll() {
        this.setHeightCouverture();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    events() {
        window.addEventListener("resize", () => {

        })
    }

    getArticle(articles) {
        return articles.filter((article) => article.slug === this.props.match.params.slug)[0];
    }

    findArticlesToChapitre(articles) {
        return articles.filter((article) => article.category.slug === this.props.match.params.chapitre);
    }

    getPrev(articles) {

        if(!articles) return;

        let currentArticle = this.getArticle(articles);
        if(!currentArticle) return;

        let get = articles
            .filter(article =>
                !isEmptyArticle(article)
            )
            .filter(article => {
            return article.positionChapitre < currentArticle.positionChapitre;
        }).sort((a, b) => {
            return (b.positionChapitre - a.positionChapitre)
        })[0];

        return get;

    }

    getNext(articles) {

        if(!articles) return;

        let currentArticle = this.getArticle(articles);
        if(!currentArticle) return;

        let get = articles
            .filter(article =>
                !isEmptyArticle(article)
            )
            .filter(article => {
            return article.positionChapitre > currentArticle.positionChapitre;
        }).sort((a, b) => {
            return (a.positionChapitre - b.positionChapitre)
        })[0];

        return get;

    }

    isLastArticle(articles) {
        return (!this.getNext(articles))
    }

    isFirstArticle(articles) {
        return (!this.getPrev(articles))
    }

    findChapitre(chapitres) {
        return chapitres.filter((chapitre) => chapitre.slug === this.props.match.params.chapitre)[0];
    }

    getPrevChapitre(chapitres) {

        if(!chapitres) return;

        let currentChapitre = this.findChapitre(chapitres);

        let get = chapitres.find(chapitre => {
            return chapitre.order === currentChapitre.order - 1;
        });

        return get;
    }

    findFirstArticleToChapitre(chapitre, articles) {

        if(!chapitre) return null;

        const articlesArr = articles
            .filter(article =>
                !isEmptyArticle(article)
            )
            .filter((article) => article.category.slug === chapitre.slug);

        let get = articlesArr.sort((a, b) => { return (a.positionChapitre - b.positionChapitre) })[0];

        return get;

    }

    findLastArticleToChapitre(chapitre, articles) {

        if(!chapitre) return null;

        const articlesArr = articles
            .filter(article =>
                !isEmptyArticle(article)
            )
            .filter((article) => article.category.slug === chapitre.slug);

        let get = articlesArr.sort((a, b) => {
            return (a.positionChapitre - b.positionChapitre)
        })[articlesArr.length - 1];

        return get;

    }

    getNextChapitre(chapitres) {

        if(!chapitres) return;

        let currentChapitre = this.findChapitre(chapitres);

        let get = chapitres.find(chapitre => {
            return chapitre.order === currentChapitre.order + 1;
        });

        return get;
    }

    isPreview = () => {
        return this.props.location.search;
    };

    getRenderDatas() {

        const articlesArr =  this.findArticlesToChapitre(this.props.allArticles);
        const next = this.getNextChapitre(this.props.categories);
        const prev = this.getPrevChapitre(this.props.categories);

        return {
            articlesArr: this.findArticlesToChapitre(this.props.allArticles),
            prev: this.getPrev(articlesArr),
            next: this.getNext(articlesArr),
            article: this.getArticle(articlesArr),
            isLastArticle: this.isLastArticle(articlesArr),
            isFirstArticle: this.isFirstArticle(articlesArr),
            nextChapitre: {
                chapitre: next,
                article: this.findFirstArticleToChapitre(next, this.props.allArticles)
            },
            prevChapitre: {
                chapitre: prev,
                article: this.findLastArticleToChapitre(prev, this.props.allArticles)
            }
        }
    }

    render() {
        const {
            articlesArr,
            prev,
            next,
            article,
            isLastArticle,
            isFirstArticle,
            nextChapitre,
            prevChapitre
        } = this.getRenderDatas();

        const classes = classNames({
            'full': this.state.fullPlayer,
            'player-outer': true,
        });

        if(this.isPreview()){
            if(this.props.previewLoading)
                return null;
        }

        return(

            <div className={ classes }>
                <div className="player-left" />
                <div className="player">
                    <Timeline categories={ this.props.categories }
                              articles={ this.props.allArticles }
                              article={ article }
                              match={ this.props.match }
                              numero={ this.props.numero }
                              fonts={ this.props.fonts }
                    />
                    <div className="btn prev">
                        <ButtonGo
                            isPrev={ true }
                            categories={ this.props.categories }
                            articles={ this.props.allArticles }
                            match={ this.props.match }
                            numero={ this.props.numero }
                            article={ article }
                            prev={ prev }
                            isFirstArticle={ isFirstArticle }
                            isLastArticle={ isLastArticle }
                            prevChapitre={ prevChapitre }
                            fonts={ this.props.fonts }
                            clickE={ this.props.captureTimelineClickPrev }
                        />
                    </div>
                    <div className="btn next">
                        <ButtonGo
                            isNext={ true }
                            categories={ this.props.categories }
                            articles={ this.props.allArticles }
                            match={ this.props.match }
                            numero={ this.props.numero }
                            article={ article }
                            next={ next }
                            isFirstArticle={ isFirstArticle }
                            isLastArticle={ isLastArticle }
                            nextChapitre={ nextChapitre }
                            fonts={ this.props.fonts }
                            clickE={ this.props.captureTimelineClickNext }
                        />
                    </div>
                </div>
                <div className="player-right" />
            </div>

        )
    }

}

const mapStateToProps = (state, props) => {
    return {
        previewLoading: state.preview.previewLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        captureTimelineClickNext: (value) => {
            dispatch({
                type: "CLICK_TIMELINE_NEXT",
            })
        },
        captureTimelineClickPrev: () => {
            dispatch({
                type: "CLICK_TIMELINE_PREV",
            })
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Player));
